import router from '@/router'
import { isNil } from 'lodash'
import {
  createNewUserFromFirebaseAuthUser,
  setCustomClaimsOnUserObj
} from '@/misc/helpers'
import Users from '@/firebase/models/users'

export default {
  /**
   * Callback fired when user login  (dispatch)
   */
  login: async ({ commit }, firebaseAuthUser) => {
    const users = new Users()
    const userFromFirebase = await users.read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase

    setCustomClaimsOnUserObj(firebaseAuthUser, user)
    commit('setUser', user)
  },

  /**
   * Callback fired when user logout
   */
  logout: ({ commit, dispatch }) => {
    commit('setUser', null)
    dispatch('products/unbindProducts', null, { root: true })

    const currentRouter = router.app.$route
    if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
      router.push('/login')
    }
  }
}
