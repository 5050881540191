import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import authentication from './authentication'
import app from './app'
import products from './products'

Vue.use(Vuex)

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  mutations: {
    ...vuexfireMutations
  },
  modules: {
    authentication,
    app,
    products
  }
})
