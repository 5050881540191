import firebase from 'firebase/app'
import db from './init'
import functions from './functions'

export default {
  install(Vue) {
    Vue.prototype.$db = db
    Vue.prototype.$firestore = firebase.firestore
    Vue.prototype.$functions = functions
  }
}
