import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics'

// The configuration below is not sensitive data. You can serenely add your config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIRESTORE_API_KEY,
  authDomain: process.env.VUE_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIRESTORE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIRESTORE_APP_ID,
  measurementId: process.env.VUE_APP_FIRESTORE_MEASUREMENT_ID
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// if (process.env.NODE_ENV !== 'development') {
//   firebase.analytics()
// }
const db = firebase.firestore()

if (process.env.NODE_ENV !== 'production') {
  window.db = db
}

export default db
