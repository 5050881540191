<template>
  <div>
    <v-app-bar app flat color="white" light>
      <v-app-bar-nav-icon
        v-if="isUserLoggedIn"
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <router-link to="/">
        <v-avatar tile size="56">
          <img :src="require('@/assets/favicon_mayo_3.png')" alt="logo" />
        </v-avatar>
      </router-link>
      <v-toolbar-title class="headline">
        <span class="font-weight-bold">HOA</span>
      </v-toolbar-title>
      <v-toolbar-items v-if="isUserLoggedIn" class="ml-4 hidden-sm-and-down">
        <v-btn
          v-for="link in navbar_links"
          :key="link.text"
          router
          :to="link.route"
          text
        >
          <v-icon left>{{ link.icon }}</v-icon>
          {{ link.text }}
        </v-btn>
        <v-menu
          min-width="200"
          flat
          color="white"
          open-on-hover
          bottom
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon left>mdi-chevron-down</v-icon>
              More
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in more_navbar_links"
              :key="index"
              :to="item.route"
            >
              <v-list-item-title>
                <v-icon left>
                  {{ item.icon }}
                </v-icon>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isUserLoggedIn">
        <v-menu
          min-width="200"
          flat
          color="white"
          open-on-hover
          bottom
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              class="no-hover-effect"
              text
              v-bind="attrs"
              :ripple="false"
              v-on="on"
            >
              <v-avatar>
                <img
                  src="https://cdn.vuetifyjs.com/images/john.jpg"
                  alt="John"
                />
              </v-avatar>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in account_dropdown_links"
              :key="index"
              :to="item.route"
            >
              <v-list-item-title>
                <v-icon left>
                  {{ item.icon }}
                </v-icon>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title
                ><v-icon left>mdi-logout</v-icon>
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn text router to="/login">
          <v-icon left>mdi-login</v-icon>
          Login
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!-- Mobile Nav Drawer -->
    <v-navigation-drawer v-model="drawer" app light disable-resize-watcher>
      <v-list dense>
        <v-list-item
          v-for="link in navbar_links"
          :key="link.text"
          router
          :to="link.route"
          :ripple="false"
          @click="panel = false"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-expansion-panels v-model="more_panel" flat>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-chevron-down">
            More
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in more_navbar_links"
                :key="index"
                :to="item.route"
                :ripple="false"
                @click="close_more_panel"
              >
                <v-list-item-title>
                  <v-icon left>
                    {{ item.icon }}
                  </v-icon>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      drawer: false,
      more_panel: false,
      navbar_links: [
        { icon: 'mdi-view-dashboard', text: 'Home', route: '/' },
        { icon: 'mdi-account-group', text: 'Groups', route: '/groups' },
        { icon: 'mdi-calendar-heart', text: 'Events', route: '/events' },
        { icon: 'mdi-notebook', text: 'Directory', route: '/directory' }
      ],
      more_navbar_links: [
        { icon: 'mdi-new-box', text: 'More to come!', route: '/more' }
      ],
      account_dropdown_links: [
        { icon: 'mdi-account', text: 'Profile', route: '/profile' }
      ]
    }
  },
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
    close_more_panel(e) {
      if (e.currentTarget.getAttribute('href') !== this.$route.path) {
        this.more_panel = false
      }
    }
  }
}
</script>

<style scoped>
.v-toolbar.v-app-bar {
  color: #2c3e50;
}

.theme--light.v-btn--active::before {
  opacity: 0;
}

.theme--light.v-list-item--link:before {
  background-color: #46bd87;
}

.theme--light.v-btn--active {
  margin-bottom: -2px;
  border-bottom: 3px solid #46bd87;
}

.v-btn--active .v-btn__content i,
.v-list-item--active .v-list-item__icon i {
  color: #46bd87;
}

.v-expansion-panel:not(:first-child)::after {
  border: none;
}

.no-hover-effect::before {
  background-color: transparent !important;
}
</style>
