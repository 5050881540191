<template>
  <div class="page-wrapper">
    <h1 class="home-page-title">{{ appTitle }}</h1>
    <v-img alt="logo-mayo-HOA" class="logo" src="@/assets/favicon_mayo_3.png" />

    <h2>Welcome to the Mayo Lake HOA Website!</h2>
    <h3>More to come soon :)</h3>

    <div v-if="user">
      <h4>My user:</h4>
      <p>
        {{ user.id }}, {{ user.email }}, Admin: {{ user.admin }}, Superuser:
        {{ user.superuser }}
      </p>
    </div>

    <div v-if="users">
      <h4>Our users</h4>
      <div>
        <p v-for="user in users" :key="user.id">
          {{ user.id }}: {{ user.name }} -- {{ user.email }}
        </p>
      </div>

      <h4>Query users</h4>
      <p>
        <span>{{ getDataFor('uid_123') }}</span>
      </p>

      <p>
        <span>{{ getDataFor('uid_abc') }}</span>
      </p>
      <p>
        <span>{{ getDataFor('uid_dne') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      users: null,
      specific_user: null
    }
  },
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('app', ['appTitle']),
    ...mapState('authentication', ['user'])
  },
  mounted() {
    if (this.user) {
      this.$db
        .collection('aggregate')
        .doc('users')
        .get()
        .then(docs => {
          if (docs.exists) {
            const data = docs.data()
            this.users = data.users
          }
        })
        .catch(err => console.log(err))
    }

    // this.$db
    //   .collection('users')
    //   .doc('tP3jdjIzQ9WQuI00bvo3kiwX61l1')
    //   .get()
    //   .then(doc => {
    //     if (doc.exists) {
    //       this.specific_user = { id: doc.id, ...doc.data() }
    //     } else {
    //       this.specific_user = { name: 'Unknown', email: 'dne' }
    //     }
    //   })
  },
  methods: {
    // setClaim() {
    //   if (this.user) {
    //     const addCustomClaim = this.$functions.httpsCallable('addCustomClaim')
    //     addCustomClaim({
    //       email: this.user.email,
    //       claims: ['superuser', 'admin']
    //     })
    //       .then(res => {
    //         console.log(res.data)
    //       })
    //       .catch(err => {
    //         console.log(err.message)
    //       })
    //   }
    // },
    getDataFor(uid) {
      const user = this.users.find(u => u.id === uid)
      if (user) {
        return `${uid}: ${user.name} -- ${user.email}`
      }
      return 'not found!!!'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }
}
</style>
