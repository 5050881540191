// import UserProductsDB from '@/firebase/user-products-db'
import { firestoreAction } from 'vuexfire'
// import db from '@/firebase/init'
import UserProducts from '@/firebase/models/user-products'
// import firestore from '../../firebase/async-firestore'
// import UsersDB from '../../firebase/users-db'

export default {
  /**
   * Fetch products of current loggedin user
   */
  // getUserProducts: async ({ rootState, commit }) => {
  //   const userProductDb = new UserProductsDB(rootState.authentication.user.id)
  //   const products = await userProductDb.readAll()
  //   console.log(products)
  //   commit('setProducts', products)
  // },

  bindProducts: firestoreAction(({ rootState, bindFirestoreRef }) => {
    return bindFirestoreRef(
      'products',
      new UserProducts(rootState.authentication.user.id)
        .readSnapshot()
        .orderBy('createTimestamp', 'desc')
    )
  }),

  unbindProducts: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('products')
  }),

  /**
   * Create a product for current loggedin user
   */
  createUserProduct: async ({ commit, rootState }, product) => {
    const userProductDb = new UserProducts(rootState.authentication.user.id)

    commit('setProductCreationPending', true)
    await userProductDb.create(product)
    commit('setProductCreationPending', false)
  },

  /**
   * Create a new product for current loggedin user and reset product name input
   */
  triggerAddProductAction: ({ dispatch, state, commit }) => {
    if (state.productNameToCreate === '') return

    const product = { name: state.productNameToCreate }
    commit('setProductNameToCreate', '')
    dispatch('createUserProduct', product)
  },

  /**
   * Delete a user product from its id
   */
  deleteUserProduct: async ({ rootState, commit, getters }, productId) => {
    if (getters.isProductDeletionPending(productId)) return

    const userProductsDb = new UserProducts(rootState.authentication.user.id)

    commit('addProductDeletionPending', productId)
    await userProductsDb.delete(productId)
    commit('removeProductDeletionPending', productId)
  }
}
