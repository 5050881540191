import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2196f3',
        secondary: '#03a9f4',
        accent: '#00bcd4',
        error: '#f44336',
        warning: '#ff9800',
        info: '#607d8b',
        success: '#4caf50'
      }
    }
  }
  //   theme: {
  //     themes: {
  //       light: {
  //         primary: colors.red.darken1, // #E53935
  //         secondary: colors.red.lighten4, // #FFCDD2
  //         accent: colors.indigo.base // #3F51B5
  //       }
  //     }
  //   }
})
