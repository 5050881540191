// import UsersDB from '@/firebase/users-db'
import firebase from 'firebase/app'
import UsersDB from '@/firebase/models/users'

/**
 * Create new user from firebase auth user infos
 */
// eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async firebaseAuthUser => {
  const providerData = firebaseAuthUser.providerData[0]
  const { displayName, photoURL, email } = providerData
  const userDb = new UsersDB()
  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
  const user = {
    displayName,
    photoURL,
    email,
    createTimestamp: serverTimestamp,
    updateTimestamp: serverTimestamp
  }
  return userDb.create(user, firebaseAuthUser.uid)
}

export const setCustomClaimsOnUserObj = (user, userData) => {
  user.getIdTokenResult().then(token => {
    userData.admin = token.claims.admin
    userData.superuser = token.claims.superuser
  })
}
